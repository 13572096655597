import React, { Component, Fragment } from 'react';
import './App.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { script } from './scripts/script1';
import { Script } from './models/script';
import { ScriptNode } from './models/script-node';
import { isNumber } from 'util';
import { SaveData } from './models/save-data';
import ReactGA from 'react-ga';
import YouTube, { Options } from 'react-youtube';
import { element } from 'prop-types';
import { ScriptLink } from './models/script-link';
import DeviceTypeService from './services/device-type-service';
import { ScriptSwitch } from './models/script-switch';
import TileGame from './components/tile-game/TileGame';

interface IAppState
{
  anchorEl: any;
  goToNodeOpen: boolean;
  advertisingOpen: boolean;
  goToNodeId: string;
  currentNodeTaps: number;
  currentNode: ScriptNode;
  inputValue: string;
  preloadedImages: string[];
}

class App extends Component<any, IAppState> {

  script: Script;
  saveData: SaveData;
  deviceTypeService: DeviceTypeService = new DeviceTypeService();
  endText: string = "";

  constructor(props: any)
  {
    super(props);

    this.script = script;

    var saveDataString = localStorage.getItem("saveData");
    this.saveData = saveDataString == null 
      ? new SaveData() 
      : JSON.parse(saveDataString);

    console.log(this.saveData);

    this.state = {
      anchorEl: null,
      goToNodeOpen: false,
      advertisingOpen: false,
      goToNodeId: "",
      currentNodeTaps: 0,
      currentNode: this.script.nodes.find(x => x.id == this.saveData.nodeId) || this.script.nodes[0],
      inputValue: "",
      preloadedImages: []
    };

    console.log(this.state.currentNode);
    console.log(process.env.NODE_ENV);
  }

  handleMenuDevGoToNode = (event: any) => {
    this.goToNodeOpen(true);
    this.menuClose();
  };

  handleMenuAdvertising = (event: any) => {
    this.advertisingOpen(true);
    this.menuClose();
  };

  handleMenuPrintSaveData = (event: any) => {
    console.log(this.saveData);
    this.menuClose();
  };

  handleMenuClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.menuClose();
  };

  menuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleGoToNodeId = () => {
    var nodeId = 1;
    try 
    {
      nodeId = parseInt(this.state.goToNodeId);
    }
    catch (ex) {}

    var nextNode = this.script.nodes.find(x => x.id == nodeId) || new ScriptNode();
    this.moveToNode(nextNode);
    this.goToNodeOpen(false);
  };

  handleGoToNodeClose = () => {
    this.goToNodeOpen(false);
  };

  goToNodeOpen = (doOpen: boolean) => {
    this.setState({ goToNodeOpen: doOpen });
  }

  handleAdvertisingClose = () => {
    this.advertisingOpen(false);
  }

  advertisingOpen = (doOpen:boolean) => {
    this.setState({advertisingOpen: doOpen});
  }

  handleTileGameTap = (message: string) => {
    this.setState({ currentNodeTaps: this.state.currentNodeTaps + 1 });

    var node = this.state.currentNode;
    node.text = message;
    this.setState({ currentNode: node })
  }

  handleTap = (buttonId: number = -1) => {
    var hasMovedNode = false;
    var wouldHaveTappedEnough = this.state.currentNodeTaps + 1 >= this.totalNodeTaps();
    if (wouldHaveTappedEnough)
    {
      var hasTappedButton = buttonId >= 0;
      var hasNoButtons = this.state.currentNode.buttons == undefined;
      var hasInput = this.state.currentNode.input != undefined;

      var buttonVariableId = this.state.currentNode.buttonVariableId || "";
      if (hasTappedButton && buttonVariableId != "") {
        (this.saveData as any)[buttonVariableId] = buttonId;
      }

      if (hasInput)
      {
        (this.saveData as any)[this.state.currentNode.input!.variableId] = this.state.inputValue;
      }

      if (hasTappedButton)
      {
        var actionTarget = buttonVariableId != "" 
          ? `variable-id-${buttonVariableId}`
          : `node-${this.state.currentNode.id}`;

        ReactGA.event({
          category: "Script",
          action: `click-button-on-${actionTarget}`,
          value: buttonId
        });
      }

      if (hasTappedButton || hasNoButtons)
      {
        hasMovedNode = true;
        var nextNode = this.getNextNode(buttonId);
        this.moveToNode(nextNode);
      }
      
      localStorage.setItem("saveData", JSON.stringify(this.saveData));

      console.log(this.saveData);
    }

    if (!hasMovedNode) {
      this.setState({ currentNodeTaps: this.state.currentNodeTaps + 1 });
    }
  }

  hasTappedEnough = (): boolean => {
    var totalNodeTaps = this.totalNodeTaps();
    return this.state.currentNodeTaps >= totalNodeTaps;
  }

  totalNodeTaps = (): number => {
    return (this.state.currentNode.numTaps != null ? this.state.currentNode.numTaps : this.script.numTaps) - 1;
  }

  componentDidMount = () => {
    this.initializeReactGA();
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-105834819-2');
    ReactGA.pageview('/home');
  }

  moveToNode = (node: ScriptNode) => {
    let beforeNode = this.state.currentNode;
    let afterNode = node;

    this.beforeMoveToNode(beforeNode, afterNode);

    console.log(this.state.currentNodeTaps);

    this.setState( { currentNode: node, currentNodeTaps: 0 });

    this.saveData.nodeId = node.id;

    this.afterMoveToNode(beforeNode, afterNode);
  }

  beforeMoveToNode = (beforeNode: ScriptNode, afterNode: ScriptNode) => {
    if (typeof beforeNode.ga == "string") {
      ReactGA.event({
        category: "Script",
        action: `progress-${beforeNode.ga}`
      });
    }
  }

  afterMoveToNode = (beforeNode: ScriptNode, afterNode: ScriptNode) => {
    if (afterNode.id == 0) {
      ReactGA.event({
        category: "Script",
        action: `progress-finished`
      });
    } else if (afterNode.id == 50) {
      ReactGA.event({
        category: "Script",
        action: `progress-started`
      });
    }

    this.preloadImagesForFollowingNode(afterNode);

    if (afterNode.link != undefined && isNumber(afterNode.nextId))
    {
      this.followLink(afterNode.link);
      var nextNode = this.script.nodes.find(x => x.id == afterNode.nextId) || new ScriptNode();
      this.moveToNode(nextNode);
    }
    else if (afterNode.switch != undefined)
    {
      this.performSwitch(afterNode, afterNode.switch);
    }
  }

  followLink(link: ScriptLink) {
    if (this.deviceTypeService.isAndroid()) {
      window.open(link.android, "_blank");
    } else if (this.deviceTypeService.isIphone()) {
      window.open(link.iphone, "_blank");
    } else {
      window.open(link.other, "_blank");
    }
  }

  performSwitch(node: ScriptNode, scriptSwitch: ScriptSwitch) {
    var nextNodeString = (this.saveData as any)[scriptSwitch.variableId];
    var variableValue = nextNodeString != "" ? parseInt((this.saveData as any)[scriptSwitch.variableId]) : 0;
    var nextNodeId = (node.nextId as any)[variableValue];
    console.log("next node id from switch is " + nextNodeId);
    var nextNode = this.script.nodes.find(x => x.id == nextNodeId) || new ScriptNode();
    this.moveToNode(nextNode);
  }

  preloadImagesForFollowingNode = (currentNode: ScriptNode) => {
    var nextIds: Array<number> = [];
    
    if (currentNode.buttons != undefined) {
      nextIds = Object.entries(currentNode.buttons || {}).map((button, i) => parseInt(button[i]));
    } else if (typeof currentNode.nextId === "number") {
      nextIds = [ currentNode.nextId ];
    }

    for (var nextId of nextIds) {
      var node = this.script.nodes.find(x => x.id == nextId);
      if (node && typeof node.image === "string") {
        this.state.preloadedImages.push(process.env.PUBLIC_URL + "/images/" + node.image);
      }
    }

    this.setState({ preloadedImages: this.state.preloadedImages })
  }

  getNextNode = (buttonId: number = -1): ScriptNode => {
    let currentNode: ScriptNode = this.state.currentNode;
    let nextNode: ScriptNode;

    if (currentNode.id == 0) {
      nextNode = this.script.nodes[0];
    } else if (isNumber(currentNode.nextId)) {
      nextNode = this.script.nodes.find(x => x.id == currentNode.nextId) || new ScriptNode();
    } else {
      var nextId = currentNode.nextId[buttonId];
      nextNode = this.script.nodes.find(x => x.id == nextId) || new ScriptNode();
    }

    return nextNode;
  }

  getNodeText = (): string => {
    var text =  this.state.currentNode.text;
    var tokenIndex = text.indexOf("saveData:");

    while (tokenIndex > -1) {
      var from = text.indexOf(":", tokenIndex) + 1;
      var to = text.indexOf(" ", tokenIndex);
      if (to == -1) { to = text.length; }
      var propertyName = text.substring(from, to);
      var propertyValue = (this.saveData as any)[propertyName];

      text = text.replace("saveData:" + propertyName, propertyValue);

      tokenIndex = text.indexOf("saveData:");
    }

    return text;
  }

  canShowButtons = (): boolean => {
    return this.state.currentNode.buttons != undefined && 
    this.state.currentNodeTaps >= ((this.state.currentNode.numTaps || this.script.numTaps) - 1);
  }

  handlePreloadImageLoaded(syntheticEvent: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (syntheticEvent.target instanceof HTMLImageElement)
    {
      var src = syntheticEvent.target.src;
      var i = this.state.preloadedImages.indexOf(src);
      this.state.preloadedImages.splice(i, 1);
      this.setState({ preloadedImages: this.state.preloadedImages });
    }
  }

  handleMenuPrivacy = () =>  {
    this.handleMenuClose();
    window.open("https://www.kinorogames.co.uk/privacy-policy", "_blank");
  }

  handleMenuAbout = () => {
    this.handleMenuClose();
    window.open("https://www.kinorogames.co.uk", "_blank");
  }
  
  handleMenuAboutRuss = () => {
    this.handleMenuClose();
    window.open("https://twitter.com/kinorouk", "_blank");
  }

  getMenuItems(): Array<JSX.Element>[] {
    var menuItems = [];

    menuItems.push(<MenuItem key={menuItems.length} onClick={this.handleMenuAdvertising}>Advertising</MenuItem>);
    menuItems.push(<MenuItem key={menuItems.length} onClick={this.handleMenuPrivacy}>Privacy</MenuItem>);
    menuItems.push(<MenuItem key={menuItems.length} onClick={this.handleMenuAboutRuss}>About Russ</MenuItem>);

    if (process.env.NODE_ENV == "development")
    {
      menuItems.push(<MenuItem key={menuItems.length} onClick={this.handleMenuDevGoToNode}>Dev - Go To Node</MenuItem>);
      menuItems.push(<MenuItem key={menuItems.length} onClick={this.handleMenuPrintSaveData}>Dev - Print Save Data</MenuItem>);
    }

    return menuItems;
  }

  render() {
    var nodeClasses = `${this.state.currentNode.ad === true ? 'ad' : ''}`;
    var menuItems = this.getMenuItems();

    return (
      <div className="wrapper">
        <div id="row1">
          <Button
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleMenuClick}
          >
            tapsify
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            { menuItems }
          </Menu>
        </div>
        <div id="row2" onClick={() => this.handleTap()} className={nodeClasses}>
          { this.state.currentNode.id > 0
            ? this.renderCurrentNode()
            : this.endText }
        </div>
        { this.renderGoToNodeDialog() }
        { this.renderAdvertisingDialog() }
        { this.renderPreloadedImages() }
      </div>
    );
  }

  renderCurrentNode() {
    return (
      <Fragment>
        { this.state.currentNode.image != undefined
          ? this.renderImage()
          : <Fragment></Fragment>
        }
        { this.state.currentNode.embed != undefined
          ? this.renderEmbed()
          : <Fragment></Fragment>
        }
        { this.state.currentNode.custom != undefined
          ? this.renderCustom()
          : <Fragment></Fragment>
        }
        <div key={ this.state.currentNode.groupId || this.state.currentNode.id } className="text">{ this.getNodeText() }</div>
        { this.state.currentNode.input != undefined
          ? this.renderInput()
          : <Fragment></Fragment>
        }
        { this.state.currentNode.buttons != undefined
          ? <div className="buttons">
              { this.renderButtonsList() }
            </div>
          : <Fragment></Fragment>
        }
        { !this.canShowButtons()
          ? this.renderTapsRequired() 
          : <Fragment></Fragment>
        }
      </Fragment>
    );
  }

  renderCustom() {
    return (
      <TileGame onTileGameClicked={this.handleTileGameTap}></TileGame>
    );
  }

  renderButtonsList() {
    return this.hasTappedEnough() &&
     Object.entries(this.state.currentNode.buttons || {}).map(
      (button, i) =>
      <Fragment key={ "btn" + button[0] }>
      { i > 0 ? <br/> : <span></span>}
      <Button variant="contained" color="primary" onClick={() => this.handleTap(parseInt(button[0]))}>
        { button[1] }
      </Button>
      </Fragment>
    );
  }

  renderImage() {
    return (
      <img key={ "img" + (this.state.currentNode.groupId || this.state.currentNode.id) }
        src={ process.env.PUBLIC_URL + "/images/" + this.state.currentNode.image } 
        alt={ this.state.currentNode.image }
        />  
    );
  }

  renderEmbed() {
    const opts: Options = {
      height: '400',
      width: '300',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    return (
      <YouTube
        videoId={ this.state.currentNode.embed!.youtube }
        opts={opts}
      />
    );
  }

  renderInput() {
    return (
      <TextField
        id={this.state.currentNode.input!.variableId || "" }
        label={this.state.currentNode.input!.caption || "" }
        margin="dense"
        variant="outlined"
        onChange = {(el) => this.setState({ inputValue: el.target.value })}
      />
    );
  }

  createRemainingTaps() {
    let remainingTaps = [];

    for (var i=0; i<this.totalNodeTaps(); i++) {
      if (this.state.currentNodeTaps > i) {
        remainingTaps.push(<div key={i} className="circle"></div>)
      } else {
        remainingTaps.push(<div key={i} className="circle outline"></div>)
      }
      
    }

    return remainingTaps;
  }   

  renderTapsRequired() {
    return (
      <div className="remaining-taps">
        { this.createRemainingTaps() }
      </div>
    );
  }

  renderGoToNodeDialog() {
    return (
      <Dialog
        open={this.state.goToNodeOpen}
        onClose={this.handleGoToNodeClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Dev - Go To Node</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For development only. Enter a node id to go to.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="nodeId"
            label="Node Id"
            type="text"
            onChange = {(el) => this.setState({ goToNodeId: el.target.value })}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleGoToNodeClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleGoToNodeId} color="primary">
            Go
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAdvertisingDialog() {
    return (
      <Dialog
        open={this.state.advertisingOpen}
        onClose={this.handleAdvertisingClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">So, you want to advertise?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            That's great! Simply pop an email to <a href="mailto:advertising@kinorogames.co.uk">advertising@kinorogames.co.uk</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAdvertisingClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderPreloadedImages() {
    return (
    <div style={{'visibility': 'hidden', 'width': 0, 'height': 0, 'overflow': 'hidden'}}>
      {this.state.preloadedImages.map((preloadImage) => {
        return (
          <img src={preloadImage} onLoad={(el) => this.handlePreloadImageLoaded(el)} />
        );
      })}
    </div>
    )
  }
}

export default App;
