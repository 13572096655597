import { ScriptInput } from "./script-input";
import { ScriptSwitch } from "./script-switch";
import { ScriptLink } from "./script-link";
import { ScriptEmbed } from "./script-embed";
import { ScriptCustom } from "./script-custom";

export class ScriptNode {
  id: number = 0;
  groupId?: number = 0;
  nextId: number | { [id: number]: number } = 0;
  numTaps?: number;
  buttonVariableId?: string;
  buttons?: { [id: number]: string };
  input?: ScriptInput;
  image?: string;
  ad?: boolean;
  switch?: ScriptSwitch;
  ga?: string;
  text: string = "";
  link?: ScriptLink;
  embed?: ScriptEmbed;
  custom?: ScriptCustom;
  numCurrentTaps?: number = 0;
}