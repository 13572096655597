export class DeviceTypeService
{
  userAgent: string = "";

  constructor() {
    this.userAgent = navigator.userAgent.toLowerCase();
  }

  isAndroid = (): boolean => 
  {
    return this.userAgent.indexOf("android") > -1;
  }

  isIphone = (): boolean => 
  {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
  }
}

export default DeviceTypeService;