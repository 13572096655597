import React, { Component, Fragment } from 'react';
import './TileGame.css';
import $ from 'jquery';

interface ITileGameState {

}

interface ITileGameProps {
  onTileGameClicked: { (message: string): void };
}

class TileGame extends Component<ITileGameProps, ITileGameState> {

  numTotalTaps: number = 0;
  isGameOver: boolean = false;
  message: string = "";
  showNextMsgEveryXTaps = 4;
  currentMessageIndex = 0;

  constructor(props: ITileGameProps)
  {
    super(props);
  }

  componentDidMount() {
    this.startGame();
  }

  handleTap = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    this.numTotalTaps ++;

    var messages = [
      "Great move!",
      "Very nice",
      "You've nearly done it!",
      "Hmmm maybe not",
      "I'm getting bored now",
      "Time to give up?",
      "Ok you're clearly stupid",
      "Why bother carrying on?",
      "JUST GIVE UP!!",
      "I'll be back when you finish",
      ""
    ];

    if (this.numTotalTaps % this.showNextMsgEveryXTaps == 0)
    {
      var index = this.currentMessageIndex < messages.length ? this.currentMessageIndex : messages.length - 1;
      this.message = messages[index];
      this.currentMessageIndex ++;
    }

    this.props.onTileGameClicked(this.message);
  }

  render() {
    var tilePuzzlePng = process.env.PUBLIC_URL + "/images/tile-puzzle.png";

    return (
      <div id="tile-game" onClick={this.handleTap} style={{backgroundImage: `url(${tilePuzzlePng})`}}>
      </div>
    );
  }

  //Start game
  startGame() {
    var arr = new Array(7,2,5,8,3,1,4,6,9);
    var strClass = "";

    for(var i=0; i<arr.length; i++)
    {
      if(i == (arr.length -1))
        strClass = " pointer";
      
      var $image = $('#tile-game'); 
      var tilePuzzlePng = process.env.PUBLIC_URL + "/images/tile-puzzle.png";
      $image.append('<div id="pos' + (i +1) + '" class="sq' + arr[i] + strClass +'" style="background-image:url(' + tilePuzzlePng + ')"></div>');
    }

    this.movePiece();
  }

  // Move square
  movePiece() {
    var tileGame = this;
    var $imageDiv = $('#tile-game div'); 
    $imageDiv.on("click", function rar() {
      var clickedElement = $(this);
      if(!clickedElement.hasClass("pointer"))
      {
        var $moveTo = clickedElement.attr("id").replace("pos","");
        var $pointer = ($(".pointer")).attr("id").replace("pos","");
        
        if(tileGame.validMove(parseInt($pointer),$moveTo))
        {
          // Swap classes
          var a = clickedElement;
          var b = $(".pointer");
          var aClass = a.attr("class");
          var bClass = b.attr("class");
          a.removeClass(aClass).addClass(bClass);
          b.removeClass(bClass).addClass(aClass);
          
          // Check if the puzzle is complete
          if(parseInt($moveTo) == 9)
          tileGame.checkGameOver();
        }
      }
    });	
  }

  // Validate user's move
  validMove(id: number ,move: any) {
    var arr: number[] = [];
    if(id == 1)
      arr = new Array(2,4);
    else if(id == 2)
      arr = new Array(1,3,5);
    else if(id == 3)
      arr = new Array(2,6);
    else if(id == 4)
      arr = new Array(1,5,7);
    else if(id == 5)
      arr = new Array(2,4,6,8);
    else if(id == 6)
      arr = new Array(3,5,9);
    else if(id == 7)
      arr = new Array(4,8);
    else if(id == 8)
      arr = new Array(5,7,9);
    else if(id == 9)
      arr = new Array(6,8);

    if(arr.indexOf(parseInt(move)) > -1)
      return true;
  }

  // Work out if game is over
  checkGameOver() {
    this.isGameOver = false;
    for(var i=1; i<=9; i++)
    {
      if(!($("#tile-game #pos" + i)).hasClass("sq" + i))
      {
        break;
      } else {
        if(i == 9)
        {
          this.isGameOver = true;
        }				
      }
    }
  }
}

export default TileGame;