import { Script } from '../models/script';

export const script: Script = {
  "scriptId": 1,
  "version": 1,
  "numTaps": 5,
  "nodes": [
    { "id": 10, "nextId": 50, "text": "Tap or click anywhere", "numTaps": 1 },
    { "id": 50, "nextId": 60, "text": "Well done", "image": "sarcastic-clap.gif", "numTaps": 3 },
    { "id": 60, "nextId": 70, "text": "I see you're quite the genius", "image": "quite-the-genius.gif", },
    { "id": 70, "nextId": { "0": 80 }, "buttons": { "0": "Yes" }, "text": "Have you come to see something different?", "numTaps": 2 },
    { "id": 80, "nextId": { "0": 90 }, "buttons": { "0": "Yes!" }, "text": "...something unique??", "numTaps": 2 },
    { "id": 90, "nextId": { "0": 100 }, "buttons": { "0": "Oh for crying out loud. YES!!!" }, "text": "...something spectacular???!!?!!?", "numTaps": 2 },
    { "id": 100, "nextId": 110, "text": "No need to shout" },
    { "id": 110, "nextId": 120, "text": "I mean that's just rude" },
    { "id": 120, "nextId": 130, "text": "Anyway, you are in luck" },
    { "id": 130, "nextId": 140, "text": "I'll show you something that'll blow your mind!", "image": "dog-mind-blown.gif" },
    { 
      "id": 140, 
      "nextId": { "0": 210, "1": 150, "2": 170, "3": 190 }, 
      "buttonVariableId": "playerAgeGender",
      "buttons": { "0": "Woohoo!", "1": "I'm not a woman, or young", "2": "I'm not a woman", "3": "I'm not young" }, 
      "text": "You will be a very impressed young woman :)" 
    },
    { "id": 150, "nextId": 160, "text": "Are you just going to complain the whole time?" },
    { "id": 160, "nextId": 210, "text": "Honestly..." },
    { "id": 170, "nextId": 180, "text": "Are you just going to complain the whole time?" },
    { "id": 180, "nextId": 210, "text": "Honestly..." },
    { "id": 190, "nextId": 200, "text": "Really?" },
    { "id": 200, "nextId": 210, "text": "That's very honest of you" },
    { "id": 210, "nextId": 220, "text": "Anyway..." },
    { "id": 220, "nextId": 230, "text": "Let myself introduce... myself" },
    { "id": 230, "nextId": 240, "text": "I'm Tapsy" },
    { 
      "id": 240, 
      "nextId": { "0": 250 }, 
      "text": "And you are?",
      "input": { "variableId": "playerName", "maxCharacters": 20, "caption": "Name" },
      "buttons": { "0": "Confirm" },
      "numTaps": 0,
      "ga":"finished-player-name"
    },
    { "id": 250, "nextId": 260, "text": "Hi saveData:playerName" },
    { "id": 260, "nextId": 270, "text": "I probably won't remember that" },
    { "id": 270, "nextId": { "0": 280, "1": 290 }, "text": "Mind if I call you 'mate' instead?", "buttons": { "0": "I do mind", "1": "That's fine :)" } },
    { "id": 280, "nextId": 300, "text": "I don't care mate" },
    { "id": 290, "nextId": 300, "text": "Ta saveData:playerName ;)" },
    { "id": 300, "nextId": 310, "text": "So..."},
    { "id": 310, "nextId": 320, "text": "I promised you something spectacular" },
    { "id": 320, "nextId": 330, "text": "Erm" },
    { "id": 330, "nextId": 340, "text": "Ok" },
    { "id": 340, "nextId": 350, "text": "This is awkward" },
    { "id": 350, "nextId": 360, "text": "I thought you'd be bored by now" },
    { "id": 360, "nextId": 370, "text": "But you keep tapping" },
    { "id": 370, "nextId": 380, "text": "Even now" },
    { "id": 380, "nextId": 390, "text": "Even when it seems..." },
    { "id": 390, "nextId": 400, "text": "...that I might have been lying" },
    { "id": 400, "nextId": 410, "text": "I'm impressed" },
    { "id": 410, "nextId": 420, "text": "I mean that's commitment" },
    { "id": 420, "nextId": 430, "text": "Or procrastination" },
    { "id": 430, "nextId": 440, "text": "..." },
    { "id": 440, "nextId": 450, "text": "Ok stop it now" },
    { "id": 450, "nextId": 460, "text": "Go away saveData:playerName" },
    { "id": 460, "nextId": 470, "text": "Fine, I'm ignoring you now" },
    { "id": 470, "nextId": 480, "text": "", "numTaps": 8 },
    { "id": 480, "nextId": 490, "text": "Ok fine!!" },
    { "id": 490, "nextId": 500, "text": "I give up" },
    { "id": 500, "nextId": 510, "text": "You're clearly expecting more" },
    { "id": 510, "nextId": 520, "text": "10" },
    { "id": 520, "nextId": 530, "text": "9" },
    { "id": 530, "nextId": 540, "text": "8" },
    { "id": 540, "nextId": 550, "text": "7" },
    { "id": 550, "nextId": 560, "text": "6" },
    { "id": 560, "nextId": 570, "text": "5" },
    { "id": 570, "nextId": 580, "text": "4" },
    { "id": 580, "nextId": 590, "text": "3" },
    { "id": 590, "nextId": 600, "text": "2" },
    { "id": 600, "nextId": 610, "text": "1" },
    { "id": 610, "nextId": 620, "text": "0.5" },
    { "id": 620, "nextId": 630, "text": "0.2" },
    { "id": 630, "nextId": 640, "text": "0" },
    { "id": 640, "nextId": 650, "text": "Did you enjoy that?" },
    { "id": 650, "nextId": 660, "text": "Feel the suspense?" },
    { "id": 660, "nextId": 670, "text": "The excitement?" },
    { "id": 670, "nextId": 680, "text": "There's no need" },
    { "id": 680, "nextId": 690, "text": "That was all for nothing" },
    { "id": 690, "nextId": 700, "text": "You must hate me now" },
    { "id": 700, "nextId": { "0": 710 }, "buttons": { "0": "Yes please" }, "text": "How about a quick test?", "numTaps": 2 },
    { "id": 710, "nextId": 720, "text": "Ha! Like you had a choice...", "image": "evil-laugh.gif" },
    { "id": 720, "nextId": 730, "text": "Ok, quick test and more..." },
    { "id": 730, "nextId": 740, "text": "...right after this advert" },
    { "id": 740, "groupId": 740, "nextId": 750, "ad": true, "text": "Hi there!", "image": "insert-ad-here.png" },
    { "id": 750, "groupId": 740, "nextId": 760, "ad": true, "text": "I'm a cheesy voiceover person", "image": "insert-ad-here.png" },
    { "id": 760, "groupId": 740, "nextId": 770, "ad": true, "text": "Are you looking for some premium ad space?", "image": "insert-ad-here.png" },
    { "id": 770, "groupId": 740, "nextId": 780, "ad": true, "text": "Want to be associated with a unique website?", "image": "insert-ad-here.png" },
    { "id": 780, "groupId": 740, "nextId": 790, "ad": true, "text": "Maybe you want millions of views?", "image": "insert-ad-here.png" },
    { "id": 790, "groupId": 740, "nextId": 800, "ad": true, "text": "Well...", "image": "insert-ad-here.png" },
    { "id": 800, "groupId": 740, "nextId": 810, "ad": true, "text": "You probably want to look somewhere else", "image": "insert-ad-here.png" },
    { "id": 810, "groupId": 740, "nextId": 820, "text": "What the...", "image": "insert-ad-here.png", "numTaps": 2 },
    { "id": 820, "groupId": 740, "nextId": 830, "ad": true, "text": "Cos Tapsy is a boring internet... thing", "image": "insert-ad-here.png" },
    { "id": 830, "groupId": 740, "nextId": 840, "ad": true, "text": "Who would want to advertise on th-", "image": "insert-ad-here.png" },
    { "id": 840, "groupId": 740, "nextId": 850, "text": "Ok that's it!", "image": "insert-ad-here.png" },
    { "id": 850, "groupId": 740, "nextId": 860, "text": "Stupid voicever person", "image": "insert-ad-here.png" },
    { "id": 860, "groupId": 740, "nextId": 870, "text": "Wanna advertise? Tap 'Advertising' from the menu", "image": "insert-ad-here.png", "ga":"finished-fake-ad" },
    { "id": 870, "nextId": 880, "text": "", "image": "relief.gif" },
    { "id": 880, "nextId": 890, "text": "Why is nothing ever simple?" },
    { "id": 890, "nextId": 900, "text": "So, you're still here" },
    { "id": 900, "nextId": 910, "text": "Listen..." },
    { "id": 910, "nextId": 920, "text": "I'm really sorry about earlier" },
    { "id": 920, "nextId": 930, "text": "Maybe I was a little mean" },
    { "id": 930, "nextId": 940, "text": "I'm just so lonely" },
    { "id": 940, "nextId": 950, "text": "But with time..." },
    { "id": 950, "nextId": 960, "text": "... I think we can make it work" },
    { "id": 960, "nextId": 970, "text": "Tapsy and saveData:playerName" },
    { "id": 970, "nextId": 980, "text": "Best friends forever" },
    { 
      "id": 980, 
      "nextId": { "0": 983, "1": 987 }, 
      "text": "Fancy cheering me up?",
      "buttons": { "0": "Sure", "1": "No" },
    },
    { "id": 983, "nextId": 984, "text": "Great!" },
    { "id": 984, "nextId": 985, "text": "You see" },
    { "id": 985, "nextId": 990, "text": "We are really bonding now" },
    { "id": 987, "nextId": 988, "text": "What?" },
    { "id": 988, "nextId": 989, "text": "I'll pretend you didnt say that" },
    { "id": 989, "nextId": 990, "text": "Arse" },
    { "id": 990, "nextId": 1000, "text": "Ok so, this test I mentioned" },
    { "id": 1000, "nextId": 1010, "text": "Let's see how you get on" },
    { 
      "id": 1010, 
      "nextId": { "0": 1021, "1": 1026 },
      "buttons": { "0": "Yes", "1": "No" }, 
      "text": "Tap the 'Yes' button to begin"
    },
    { "id": 1021, "nextId": 1022, "text": "Wow, you are incredible" },
    { "id": 1022, "nextId": 1023, "text": "You passed my test with 100%" },
    { "id": 1023, "nextId": 1024, "text": "You really are a genius" },
    { "id": 1024, "nextId": 1030, "text": "You deserve a reward" },
    { "id": 1026, "nextId": 1027, "text": "Oh wow" },
    { "id": 1027, "nextId": 1028, "text": "Just... wow" },
    { "id": 1028, "nextId": 1029, "text": "There's really no point continuing" },
    { "id": 1029, "nextId": 1030, "text": "But continue I shall" },
    { "id": 1030, "nextId": 1031, "text": "Is this the real life?", "image":"is-this-the-real-life.jpg" },
    { "id": 1031, "nextId": 1032, "text": "Is this just fantasy?", "image":"is-this-just-fantasy.jpg" },
    { "id": 1032, "nextId": 1033, "text": "Caught in a landslide", "image":"caught-in-a-landslide.jpg" },
    { "id": 1033, "nextId": 1034, "text": "No escape from reality", "image":"no-escape.jpg" },
    { "id": 1034, "nextId": 1035, "text": "Open your eyes", "image":"open-your-eyes.jpg" },
    { "id": 1035, "nextId": 1036, "text": "Look up to the skies and see", "image":"look-up-to-the-skies.jpg" },
    { "id": 1036, "nextId": 1037, "text": "I'm just a poor boy", "image":"im-just-a-poor-boy.jpg" },
    { "id": 1037, "nextId": 1038, "text": "I need no sympathy", "image":"i-need-no-sympathy.gif" },
    { "id": 1038, "nextId": 1039, "text": "Because I'm easy come, easy go", "image":"easy-come-easy-go.gif" },
    { "id": 1039, "nextId": 1040, "text": "Little high, little low", "image":"meh.jpg" },
    { "id": 1040, "nextId": 1041, "text": "Any way the wind blows", "image":"any-way-the-wind-blows.gif" },
    { "id": 1041, "nextId": 1042, "text": "Doesn't really matter to me", "image":"doesnt-really-matter-to-me.gif" },
    { "id": 1042, "nextId": 1043, "text": "To me", "image":"to-me.gif" },
    { "id": 1043, "nextId": 1044, "text": "...", "image":"piano.gif" },
    { "id": 1044, "nextId": 1045, "text": "Mama, just killed a man", "image":"mama-just-killed-a-man.jpg" },
    { "id": 1045, "nextId": 1046, "text": "Put a gun against his head", "image":"gun.jpg" },
    { "id": 1046, "nextId": 1047, "text": "Pulled my trigger, now he's dead", "image":"pulled-my-trigger.gif" },
    { "id": 1047, "nextId": 1048, "text": "Mama, life had just begun", "image":"life-had-just-begun.jpg" },
    { "id": 1048, "nextId": 1049, "text": "But now I've gone and thrown it all away", "image":"thrown-it-all-away.jpg" },
    { "id": 1049, "nextId": 1050, "text": "Mamaaaa", "image":"mamaaa.jpg" },
    { "id": 1050, "nextId": 1051, "text": "Ooooooooooh", "image":"ooh.gif" },
    { "id": 1051, "nextId": 1052, "text": "I don't wanna die!", "image":"i-dont-wanna-die.jpg" },
    { "id": 1052, "nextId": 1053, "text": "I sometimes wish I'd never been born at all", "image":"wish-never-been-born.gif" },
    { "id": 1053, "nextId": 1054, "text": "(guitar solo)", "image":"brian-may-1.gif" },
    { "id": 1054, "nextId": 1055, "text": ".", "image":"brian-may-3.jpg" },
    { "id": 1055, "nextId": 1056, "text": "..", "image":"brian-may-4.jpg" },
    { "id": 1056, "nextId": 1057, "text": "...", "image":"brian-may-5.jpg" },
    { "id": 1057, "nextId": 1058, "text": "(end solo - thanks Brian)", "image":"brian-may-2.gif" },
    { "id": 1058, "nextId": 1059, "text": "I see a little silhouetto of a man", "image":"silhouetto.jpg" },
    { "id": 1059, "nextId": 1060, "text": "Scaramouch, scaramouch, will you do the Fandango?", "image":"fandango.gif" },
    { "id": 1060, "nextId": 1061, "text": "Thunderbolt and lightning, very, very frightening me", "image":"lightning-frightening.gif" },
    { "id": 1061, "nextId": 1062, "text": "(Galileo) Galileo (Galileo) Galileo, Galileo Figaro", "image":"galileo.jpg" },
    { "id": 1062, "nextId": 1063, "text": "Magnifico-o-o-o", "image":"magnifico.gif" },
    { "id": 1063, "nextId": 1064, "text": "I'm just a poor boy, nobody loves me", "image":"nobody-loves-me.gif" },
    { "id": 1064, "nextId": 1065, "text": "He's just a poor boy from a poor family", "image":"poor-family.png" },
    { "id": 1065, "nextId": 1066, "text": "Spare him his life from this monstrosity", "image":"monstrosity.jpg" },
    { "id": 1066, "nextId": 1067, "text": "", "image":"piano-cat.gif" },
    { "id": 1067, "nextId": 1068, "text": "Easy come, easy go, will you let me go?", "image":"will-you-let-me-go.jpg" },
    { "id": 1068, "nextId": 1069, "text": "Bismillah! No, we will not let you go", "image":"never-let-go.gif" },
    { "id": 1069, "nextId": 1070, "text": "Let him go", "image":"let-it-go.gif" },
    { "id": 1070, "nextId": 1071, "text": "Bismillah! We will not let you go", "image":"will-not-let-you-go.jpg" },
    { "id": 1071, "nextId": 1072, "text": "Let him go", "image":"let-it-go-2.jpg" },
    { "id": 1072, "nextId": 1073, "text": "Bismillah! We will not let you go", "image":"will-not-let-you-go.jpg" },
    { "id": 1073, "nextId": 1074, "text": "Let me go-o-o-o-o", "image":"let-me-go-o-o.jpg" },
    { "id": 1074, "nextId": 1075, "text": "No, no, no, no, no, no, no", "image":"no-no-no.gif" },
    { "id": 1075, "nextId": 1076, "text": "Oh mama mia, mama mia, mama mia let me go", "image":"oh-mama-mia-mama-mia.gif" },
    { "id": 1076, "nextId": 1077, "text": "Beelzebub has the devil put aside for me", "image":"bye-jack.gif" },
    { "id": 1077, "nextId": 1078, "text": "For me", "image":"bye-jack-2.jpg" },
    { "id": 1078, "nextId": 1079, "text": "For meeeeeeeeee", "image":"for-meee.gif" },
    { "id": 1079, "nextId": 1080, "text": "", "image":"head-bob.gif" },
    { "id": 1080, "nextId": 1081, "text": "So you think you can stone me and spit in my eye", "image":"stone-and-spit-in-eye.jpg" },
    { "id": 1081, "nextId": 1082, "text": "So you think you can love me and leave me to die", "image":"love-and-leave-to-die.gif" },
    { "id": 1082, "nextId": 1083, "text": "Whoah baby, can't do this to me baby", "image":"he-left-us.jpg" },
    { "id": 1083, "nextId": 1084, "text": "Just gotta get out, just gotta get right out of here", "image":"get-right-out-of-here.gif" },
    { "id": 1084, "nextId": 1085, "text": "Ooooo yeah, ooooo yeah", "image":"brian-may-end.gif" },
    { "id": 1085, "nextId": 1086, "text": "Nothing really matters, anyone can see", "image":"freddie-1.gif" },
    { "id": 1086, "nextId": 1087, "text": "Nothing really matters", "image":"freddie-2.gif" },
    { "id": 1087, "nextId": 1088, "text": "Nothing really matters to me", "image":"freddie-3.gif" },
    { "id": 1088, "nextId": 1089, "text": "Any way the wind blows", "image":"freddie-4.gif", "ga":"finished-bohemian-rhapsody" },
    { "id": 1089, "nextId": 1090, "text": "" },
    { 
      "id": 1090, 
      "nextId": { "0": 1091, "1": 1095, "2": 1098 },
      "buttonVariableId": "enjoyedBohemianRhapsody",
      "buttons": { "0": "Yes Tapsy, I loved that!", "1": "Meh", "2": "No, can I leave now?" }, 
      "text": "Well, wasn't that something?"
    },
    { "id": 1091, "nextId": 1092, "text": "Aww, thanks saveData:playerName" },
    { "id": 1092, "nextId": 1093, "text": "I need more friends" },
    { "id": 1093, "nextId": 1094, "text": "PLEASE send me some of yours!", "image": "please.gif" },
    { "id": 1094, "nextId": 1100, "text": "Ok, more to come - but first..." },
    { "id": 1095, "nextId": 1096, "text": "Really? Just... meh?", "buttons": { "0": "Afraid so" } },
    { "id": 1096, "nextId": 1097, "text": "Right - well, bye then", "image": "angry-goodbye.gif" },
    { "id": 1097, "nextId": 1100, "text": "" },
    { "id": 1098, "nextId": 1099, "text": "I hope you're joking", "buttons": { "0": "No, you're rubbish Tapsy" } },
    { "id": 1099, "nextId": 1100, "text": "Fine - goodbye forever!", "image": "angry-goodbye.gif" },
    { "id": 1100, "groupId": 1100, "nextId": 1110, "ad": true, "text": "Hi there!", "image": "insert-ad-here.png" },
    { "id": 1110, "groupId": 1100, "nextId": 1120, "ad": true, "text": "Cheesy voiceover person again", "image": "insert-ad-here.png" },
    { "id": 1120, "groupId": 1100, "nextId": { "0": 1130 }, "ad": true, "text": "Miss me?", "image": "insert-ad-here.png", "buttons": { "0": "No" } },
    { "id": 1130, "groupId": 1100, "nextId": 1140, "ad": true, "text": "Aww shucks - I missed you too", "image": "insert-ad-here.png" },
    { "id": 1140, "groupId": 1100, "nextId": 1150, "ad": true, "text": "Do you like puzzle games?" },
    { "id": 1150, "groupId": 1100, "nextId": 1160, "ad": true, "text": "Need to kill some time?" },
    { "id": 1160, "groupId": 1100, "nextId": 1170, "ad": true, "text": "Searching for answer..." },
    { "id": 1170, "groupId": 1100, "nextId": 1180, "ad": true, "text": "Hello Human", "embed": { "youtube": "Vd79K8o095g" } },
    { 
      "id": 1180, "groupId": 1100, "ad": true, "text": "", "embed": { "youtube": "Vd79K8o095g" }, 
      "nextId": { "0": 1181, "1": 1189 }, 
      "buttons": { "0": "I wanna play!", "1": "Not right now" },
      "buttonVariableId": "wannaPlayOrbZen"
    },
    { 
      "id": 1181, "groupId": 1100, "nextId": 1182, "ad": true, "text": "", 
      "link": {
        "android": "https://play.google.com/store/apps/details?id=com.kinorogames.hellohuman",
        "iphone": "https://apps.apple.com/us/app/hello-human/id1501313357?ls=1",
        "other": "https://www.kinorogames.co.uk/hello-human"
      }
    },
    { "id": 1182, "groupId": 1100, "nextId": 1190, "ad": true, "text": "Glad I could help - take care!" },
    { "id": 1189, "nextId": 1190, "ad": true, "text": "Maybe next time friend!" },
    { 
      "id": 1190, "text": "", 
      "nextId": { "0": 1200, "1": 1191 },
      "switch": { "variableId": "enjoyedBohemianRhapsody" } 
    },
    { "id": 1191, "nextId": 1192, "text": "Ugh" },
    { "id": 1192, "nextId": 1193, "text": "You again" },
    { "id": 1193, "nextId": 1194, "text": "Thought you would have left" },
    { "id": 1194, "nextId": 1195, "text": "I guess you want me to carry on then?" },
    { "id": 1195, "nextId": 1196, "text": "", "image": "i-dont-give-a-shit.gif" },
    { "id": 1196, "nextId": 1197, "text": "THE END" },
    { "id": 1197, "nextId": 1198, "text": "" },
    { "id": 1198, "nextId": 1199, "text": "Got ya! You're forgiven" },
    { "id": 1199, "nextId": 1200, "text": "But don't upset me again" },
    { "id": 1200, "nextId": 1210, "text": "So, I wanna play a game" },
    { "id": 1210, "nextId": 1220, "text": "You should know..." },
    { "id": 1220, "nextId": 1230, "text": "92% of players win in 15 seconds or less" },
    { "id": 1230, "nextId": 1240, "text": "But we know you're a genius" },
    { "id": 1240, "nextId": 1250, "text": "So you will do better" },
    { "id": 1250, "nextId": 1260, "text": "I'm sure of it" },
    { 
      "id": 1260, "text": "Ready?",
      "nextId": { "0": 1261 },
      "buttons": { "0": "Bring it on" },
    },
    { 
      "id": 1261, "text": "", 
      "nextId": { "0": 1262, "1": 1263, "2": 1264, "3": 1265 },
      "switch": { "variableId": "playerAgeGender" } 
    },
    { "id": 1262, "nextId": 1270, "text": "That's the spirit young lady!" },
    { "id": 1263, "nextId": 1270, "text": "That's the spirit good man!" },
    { "id": 1264, "nextId": 1270, "text": "That's the spirit young man!" },
    { "id": 1265, "nextId": 1270, "text": "That's the spirit my dear!" },
    { "id": 1270, "nextId": 1280, "text": "Ok here we go" },
    { "id": 1280, "nextId": 1290, "text": "There's two simple rules" },
    { "id": 1290, "nextId": 1300, "text": "1. Tap a tile to move it to empty space" },
    { "id": 1300, "nextId": 1310, "text": "2. Arrange the tiles to make the picture", "ga":"started-tile-game" },
    { 
      "id": 1310, "nextId": { "0": 1320 }, "text": "", 
      "numTaps": 5,
      "buttons": { "0": "I give up" },
      "custom": { "name": "tile-game" }
    },
    { "id": 1320, "nextId": 1330, "text": "Hahaha!!", "ga":"finished-tile-game" },
    { "id": 1330, "nextId": 1340, "text": "Did I mention it was impossible?" },
    { "id": 1340, "nextId": 1350, "text": "I know what you're thinking" },
    { "id": 1350, "nextId": 1360, "text": "Tapsy is mean", "numTaps": 4 },
    { "id": 1360, "nextId": 2000, "text": "I don't care!" },
    { "id": 2000, "nextId": 2010, "text": "But listen..." },
    { "id": 2010, "nextId": 2020, "text": "I've been thinking..." },
    { "id": 2020, "nextId": 2030, "text": "I promised you something different" },
    { "id": 2030, "nextId": 2040, "text": "Something that will blow your mind" },
    { "id": 2040, "nextId": 2050, "text": "Well" },
    { "id": 2050, "nextId": 2060, "text": "I think you're ready" },
    { "id": 2060, "nextId": 2070, "text": "You're gonna love this" },
    { "id": 2070, "nextId": 2080, "text": "Here is comes..." },
  ]
};